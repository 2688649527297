<template>
    <div class="w-full h-full bg-white rounded-md">
        <div class="card-top px-4 mb-2 flex justify-between items-center border-b border-gray-550">
            <div class="card-title flex items-center">
                <span class="anticon w-10 h-10">
                    <font-awesome-icon icon="shopping-basket" class="text-blue-500 text-base h-10"/>
                </span>
                <span class="text-lg font-medium ml-3">{{$t('customer.liveSale.productBlock.products')}}</span>
            </div>
        </div>
        <div class="h-scroll overflow-y-auto max-h-full" id="customer-products">
            <div class="w-full py-3 border-b border-gray-550 flex flex-wrap items-center px-4" v-if="this.$store.state.products.length === 0 && this.$store.state.highlightProduct.length === 0">
              <p class="text-darkwhite-950">{{ $t('customer.liveSale.productBlock.no-products') }}</p>
            </div>
    <!--         highlightProduct -->
          <div  class="w-full highlight-product py-3 border-b border-gray-550 flex flex-wrap items-center px-4" v-if=" this.$store.state.highlightProduct.length > 0" >
            <div class="w-3/12 mr-2">
              <img :src="this.$store.state.highlightProduct[0].image_urls"  style="max-height: 70px" alt="">
            </div>
            <div class="w-8/12">
              <div class="pro-title text-base font-normal">{{this.$store.state.highlightProduct[0].title}}</div>
              <div class="pro-desc text-xs pt-1 pb-3">
                {{this.$store.state.highlightProduct[0].description}}
              </div>
              <div class="pro-price">
                <span class="text-base">{{this.$store.state.highlightProduct[0].discount_price}} TL</span> <span class="text-xs text-gray-300 line-through" v-if="this.$store.state.highlightProduct[0].discount_price > this.$store.state.highlightProduct[0].price" >{{this.$store.state.highlightProduct[0].price}} TL</span>
              </div>
            </div>
            <div class="w-full my-2 flex items-center justify-between">
              <a-button
                  target="_blank"
                  :href="'https://api.onlivesale.com/product-link/'+this.$store.state.highlightProduct[0].id"
                  type="primary"
                  class="bg-blue-500 border-blue-500 font-normal text-xs text-white hover:text-white hover:bg-blue-600 w-2/5 px-5 py-1 h-8 rounded-md"
              >
                {{ $t('customer.liveSale.productBlock.go-product') }}
              </a-button>
              <div class="text-blue-300 flex items-center">
                        <span class="anticon w-10 h-auto">
                            <font-awesome-icon icon="star" class="text-blue-300 text-base h-10"/>
                        </span>
                {{ $t('customer.liveSale.productBlock.featured-now') }}
              </div>

              <div class="text-blue-300 flex items-center">
                        <span class="anticon w-10 h-auto">
                            <font-awesome-icon icon="location-arrow" class="text-blue-300 text-base h-10"/>
                        </span>
                {{this.$store.state.highlightProduct[0].statistics.click===undefined ? 0:this.$store.state.highlightProduct[0].statistics.click}}
              </div>
            </div>


          </div>
          <!--         /highlightProduct -->

          <div  class="w-full py-3 border-b border-gray-550   flex flex-wrap items-center px-4" v-for="product in this.$store.state.products" :key="product.id">
                <div class="w-3/12 mr-2">
                    <img :src="product.image_urls" alt="" style="max-height: 70px" >
                </div>
                <div class="w-8/12">
                    <div class="pro-title text-base font-normal">{{product.title}}</div>
                    <div class="pro-desc text-xs pt-1 pb-3">
                      {{product.description}}
                    </div>
                    <div class="pro-price">
                        <span class="text-base">{{product.discount_price}} TL</span> <span class="text-xs text-gray-300 line-through" v-if="product.discount_price > product.price">{{product.price}} TL</span>
                    </div>
                </div>
                <div class="w-full my-2 flex items-center justify-between">
                    <a-button
                        target="_blank"
                        :href="'https://api.onlivesale.com/product-link/'+product.id"
                        type="primary" 
                        class="bg-blue-500 border-blue-500 font-normal text-xs text-white hover:text-white hover:bg-blue-600 w-2/5 px-5 py-1 h-8 rounded-md"
                    >
                      {{ $t('customer.liveSale.productBlock.go-product') }}
                    </a-button>
                    <div class="text-blue-300 flex items-center">
                        <span class="anticon w-10 h-auto">
                            <font-awesome-icon icon="location-arrow" class="text-blue-300 text-base h-10"/>
                        </span>
                        {{product.statistics.click > 0 ? product.statistics.click:0}}
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
  data() {
      return {
      }
  },
  created() {

  }
}
</script>

<style>
.highlight-product{
  background-color: rgba(254,215,170,0.5) !important;
}
</style>