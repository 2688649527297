<template>
    <div class="w-full md:px-5 sm:p-0">
        <div class="live-info">
            <div class="live-title text-2xl mb-3">
              {{ this.$store.state.liveVideo.title }}
            </div>
            <div  id="video-container" style="width: 100%; background-color: rgb(0, 0, 0);">
              <div v-show="!this.$store.state.isPlaying"  style="color: #F2F2F2;font-size: 20px; text-align: center;padding: 30px;height: 56vh"><p  >{{streamStatus}}</p></div>
<!--              <video id="video-player" controls playsinline></video>-->
              <font-awesome-icon id="hide-campaign-button" icon="window-close" @click="hideCampaign" v-show="this.$store.state.campaign.show"  class="text-gray-300 text-xs"/>
              <img id="campaign"  :src="this.$store.state.campaign.image"  v-show="this.$store.state.campaign.show" alt="" srcset="" @click="showCampaignModal">
            </div>

            <div class="live-title text-sm flex justify-between items-center sm:my-3" style="height: 45px;padding: 0px;background-color: #fff;margin: 0px;">
                <div class="flex items-start">
                  <div class="flex items-center ml-3 mr-3 text-red-600">
                      <span class="anticon mr-2 relative" >
                          <font-awesome-icon icon="user" class="text-red-600 text-xs"/>
                      </span>
                    {{ this.$store.state.viewerCount }}

                  </div>

                  <div class="flex items-center mr-3">
                      <span class="anticon mr-2 relative" v-if="this.$store.state.liveVideo.date">
                          <font-awesome-icon icon="calendar-alt" class="text-gray-300 text-xs"/>
                      </span>
                    {{ this.$store.state.liveVideo.date }}

                  </div>
                  <div class="flex items-center">
                      <span class="anticon mr-2 relative" v-if="this.$store.state.liveVideo.date">
                          <font-awesome-icon icon="clock" class="text-gray-300 text-xs"/>
                      </span>
                      {{ this.$store.state.liveVideo.hour }}
                  </div>
                </div>
                <div class="like-btn-wrapper float-right">
                  <div id="hearContainer" @click="likeButton">
                      <img id="bigHeart" src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/183204/heart_(1).png" alt="heart" height="90%" width="90%">
                  </div>
                </div>
            </div>

        </div>
        </div>

</template>


<script>
import axios from "axios";

export default {
  data() {
    return {
      likeCount:0,
      playbackUrl: '',
      liveProductBarVisible: false,
      liveProducts: [],
      campaignData: [],
      isPlaying:false,
      player:{},
      videoElement:{},
      videoContainerElement:{},
      streamStatus:"Yayın bekleniyor...",
      previousLikesCount:false,
      lastLikesCount:0,
      heartsCounter:0,
      heartsInterval : null,
      interval:null,
      playerProcess:false

    }
  },
  unmounted() {
      try {
        clearInterval(this.interval)
      }catch (e){
        console.log(e)
      }
      this.removeVideo();
  },
  methods: {
    removeVideo(){
      console.log('remove')

      try {
        if (typeof this.videoElement.remove !== "undefined"){
          this.videoElement.remove()
        }
      }catch (e){
        console.log(e)
      }
      try {
        if (typeof this.player.getIVSPlayer !== "undefined") {
          const player = this.player.getIVSPlayer()
          player.delete()
        }
      }catch (e){
        console.log(e)
      }
    },
    likeButton() {

      if (!this.$store.state.is_customer_logged){
        this.$swal({
          icon:'warning',
          title:this.$t('customer.liveSale.chatBlock.login-for-like'),
          showCancelButton:true,
          confirmButtonText:this.$t('customer.liveSale.chatBlock.login'),
          cancelButtonText:this.$t('customer.liveSale.chatBlock.cancel')
        }).then((result) => {
          if (result.isConfirmed){
            this.$router.push({name:'customerLogin'})
          }
        })
        return
      }

      if (!this.$store.state.customer.verified){
        this.$swal({
          icon:'warning',
          title:this.$t('auth.verifyEmail.verification-required-title'),
          text:this.$t('auth.verifyEmail.verification-required-text')
        })
        return
      }

      if (this.likeCount === 0){
        setTimeout(() => this.sendLikeRequest(), 5000)
      }
      this.likeCount++

      this.animateHeart('like')
    },
    animateHeart(path){

      const bigHeart = document.getElementById("bigHeart");
      const cloneBtn = bigHeart.cloneNode(true);

      document.getElementById("hearContainer").appendChild(cloneBtn);
      cloneBtn.classList.add(path)
      const randomNumber = Math.floor(Math.random() * 10) + 5;

      setTimeout(() => {
        cloneBtn.classList.add("h-animate");
        var style = `width=${randomNumber}px;`;
        style += document.querySelectorAll(".h-animate").length %2 == 0 ? `left:-${randomNumber - 10}px;` : `right:${randomNumber*2}px;`;
        cloneBtn.style.cssText = style;
      }, 500);
      setTimeout(() => {
        cloneBtn.remove();
      }, 2500);
    },
    sendLikeRequest(){

      let likeCountSwap = this.likeCount
      this.likeCount = 0

      axios.post('https://r8l66126yf.execute-api.eu-west-1.amazonaws.com/updateEventLikes/eventlike', {
        id:this.$store.state.eventId.toString(),
        count: likeCountSwap,
        user_id: this.$store.state.customer.id.toString()
      }).catch(() => {
            console.log('Beğeni sayısı gönderilirken hata oluştu.')
          }
      )

    },
    calculatePercent() {

      if (this.liveProducts.length > 0) {
        this.liveProductBarVisible = true;
        this.liveProducts.forEach((item) => {
          var percent = 0;
          percent = 100 * (item.metadata_time) / (Date.now() - Date.parse(this.$store.state.liveVideo.liveDate));
          if (percent < 0 || percent > 100) {
            percent = 100
          }
          if (percent > 95) {
            percent = percent - 2;
          }
          item.percent = percent;
        })
      }
    },
    loadIvsPlayer() {
          if(this.isPlaying){
            return false;
          }
          console.log('loadIvsPlayer')

         if (window.IVSPlayer.isPlayerSupported) {
           let PlayerState = {};
           PlayerState = window.IVSPlayer.PlayerState;
           let PlayerEventType = {};
           PlayerEventType = window.IVSPlayer.PlayerEventType
           this.player = window.IVSPlayer.create();
           this.removeVideo();

           this.videoElement = document.createElement('video')
           this.videoElement.id = 'stream-video'
           this.videoElement.controls = true
           this.videoElement.playsinline  = true
           this.videoElement.autoplay  = true
           this.videoElement.loop  = true
           this.videoElement.setAttribute('webkit-playsinline', 'webkit-playsinline');
           this.videoElement.setAttribute('playsinline', 'playsinline');


           this.player.attachHTMLVideoElement(this.videoElement)

           this.player.addEventListener(PlayerState.PLAYING,  ()=> {
             console.log("Player State - PLAYING");
             // this.videoElement.style.maxHeight = "57vh";
             this.videoElement.style.width = "100%";
             this.isPlaying= true;
             this.$store.state.isPlaying= true;
              if (document.getElementById('video-container') !== undefined && document.getElementById('stream-video') === null){
                document.getElementById('video-container').appendChild(this.videoElement)
              }
           });
           this.player.addEventListener(PlayerState.ENDED, ()=> {
             console.warn("Player END:");
             this.isPlaying= false;
             this.$store.state.isPlaying= false;
             this.removeVideo();

             this.streamStatus = "Yayıncı yayını sonlandırdı."

           });
           this.player.addEventListener(PlayerState.READY, ()=> {
             console.log("Player State - READY");
           });
           this.player.addEventListener(PlayerEventType.ERROR,  ()=> {
             console.warn("Player Event - ERROR:");
             this.removeVideo();

           });
           this.player.addEventListener(PlayerEventType.TEXT_METADATA_CUE, (cue) => {
             const metadataText = cue.text;
             var data  =JSON.parse(metadataText);
             console.log(data)
             if (data.type=='product'){
               if (this.$store.state.liveVideo.liveDate){
                 if (this.liveProductBarVisible === false){
                   this.liveProductBarVisible = true
                 }
               }
               var images = JSON.parse(data.data.image_urls);
               var product = data.data;
               product.image_urls =images;
               product.percent =98;
               product.metadata_time =Date.now()-Date.parse(this.$store.state.liveVideo.liveDate);
               var control = this.$store.state.products.filter((item)=> item.id === product.id)
               if (control.length === 0){
                 this.$store.state.products.push(product);
               }
             }
             else if(data.type=='campaign'){
               if (data.data.image !==''){
                 this.$store.state.campaign.image = data.data.image;
                 this.$store.state.campaign.show = true;
                 this.campaignData.title = '<p style="text-align: left">'+data.data.title+'</p>';
                 this.campaignData.text = '<p style="text-align: left">'+data.data.description+'</p>';
                 this.campaignData.text += '<p style="text-align: left;margin-top: 30px">'+'<strong>Kampanya Kodu : </strong>'+ data.data.code+'</p>';
                 this.campaignData.imageUrl = data.data.image;
                 this.showCampaignModal();
               }
             }
             else if(data.type=='remove-product'){
               this.$store.state.products = this.$store.state.products.filter(item => item.id !== data.data.id)
               if(this.$store.state.highlightProduct.length>0 ){
                 if ( this.$store.state.highlightProduct[0].id == data.data.id){
                   this.$store.state.highlightProduct = [];
                 }
               }
             }
             else if(data.type=='highlight-product'){
               if (this.$store.state.highlightProduct.length>0){
                 this.$store.state.products.push(this.$store.state.highlightProduct[0]);
               }
               this.$store.state.highlightProduct = this.$store.state.products.filter(item => item.id === data.data.id)
               this.$store.state.products = this.$store.state.products.filter(item => item.id !== data.data.id)

             }
             else if(data.type=='remove-campaign'){
               this.$store.state.campaign.image = "";
               this.$store.state.campaign.show = false;
               this.$swal.close();
             }
             else if(data.type=='product-click'){
               this.$store.state.products.forEach((item)=>{
                 if (item.id == data.productId){
                   item.statistics = {
                     click : data.click
                   }
                 }
               })
               if (this.$store.state.highlightProduct[0].id == data.productId){
                   this.$store.state.highlightProduct[0].statistics = {
                     click : data.click
                   }
               }
             }
             else if (data.type =='likes-count'){
               const metadataText = cue.text;
               let data  =JSON.parse(metadataText);
               this.lastLikesCount = data.data.count
             }
             else if (data.type =='chat-state'){
               this.$store.state.showChat = data.data.use_chat
             }
             else if (data.type =='viewer-count'){
               this.$store.state.viewerCount = data.data.count
             }
           });
           this.player.load(this.playbackUrl);
           this.player.play();
         }
     },
    hideCampaign(){
      this.$store.state.campaign.show = false;
    },
    showCampaignModal() {
      this.$swal({
        title: this.campaignData.title,
        html: this.campaignData.text,
        imageUrl: this.campaignData.imageUrl,
        imageAlt: this.campaignData.title,
      })
    }
  },
  watch: {
    '$store.state.liveVideo.playbackUrl': function () {
      this.playbackUrl = this.$store.state.liveVideo.playbackUrl;
      console.log('watch çalıştı')
      console.log(this.playbackUrl)
      this.loadIvsPlayer();
    },
    '$store.state.products': function () {
      this.liveProducts = this.$store.state.products;
    },
    lastLikesCount(val){
      if (this.previousLikesCount === false){
        this.previousLikesCount =  this.$store.state.statistics.totalLike
        return
      }
      this.heartsCounter = val - this.previousLikesCount

      this.heartsInterval = setInterval(() => {
        this.heartsCounter--
        this.animateHeart('metadata')
      }, 4000/this.heartsCounter)
      this.previousLikesCount = val
    },
    heartsCounter(val){

      if (val === 0)
        clearInterval(this.heartsInterval)
    }
  },
  mounted() {
    this.$store.state.isPlaying = false
    this.$store.state.viewerCount = 0

    if (typeof this.$store.state.channelType !== 'undefined'){
      if (this.$store.state.channelType != "BASIC"){
        setInterval(()=>{
          this.$appAxios.post('/addQualityLog', {
            event_id:this.$store.state.eventId.toString(),
            customer_session_id: this.player.getSessionId(),
            quality: this.player.getQuality(),
          })
        },5000);
      }
    }


    this.$loadScript("https://player.live-video.net/1.8.0/amazon-ivs-player.min.js")
        .then(() => {

          this.interval =  setInterval(() => {
            if(!this.playerProcess){
              console.log('process started')
              this.playerProcess = true;
              if (!this.isPlaying){
                this.player = {};
                this.playbackUrl = this.$store.state.liveVideo.playbackUrl;
                this.loadIvsPlayer();
              }
              this.playerProcess = false;
              console.log('process ended')

            }

          }, 8000);
        })
        .catch(() => {
        });
  }
}
</script>

<style scoped>
video {
  max-height: 550px !important;
  width: 100%;

}

video::-webkit-media-controls-timeline,
video::-webkit-media-controls-current-time-display {
  display: none;
}


 #video-container{
   position: relative;
   background-color: black;
}


#campaign {
  z-index: 50;
  position: absolute;
  bottom: 0;
  width: auto;
  max-height: 120px;
  right: 0;
}

#hide-campaign-button {
  z-index: 100;
  position: absolute;
  bottom: 70px;
  right: 10px;
}
#video-player{
  background-color: black;
}
#bigHeart.metadata{
  opacity: 0.3;
}


</style>