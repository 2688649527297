<template>
    <p v-if="EventIsNotLive" style="height: 100vh;margin-top: 150px;width: 100%;text-align: center;">
      {{ $t('customer.liveSale.event-is-not-live') }}
    </p>
    <div v-else-if="this.$store.state.emptyEvent"  style="flex: 1;min-height: 100vh;height: 100%"  >
      <div class="flex justify-center" style="margin-top: 125px">
        <h2 style="font-size: 35px">{{ $t('LiveSale.stream-not-found') }}</h2>
      </div>
      <div class="flex justify-center" style="margin-bottom: 18px" v-if="!this.$store.state.iframe">
        <p>{{ $t('LiveSale.stream-now') }}</p>
      </div>
      <div class="flex justify-center " style="margin-bottom: 80px">
        <button
            v-if="!this.$store.state.is_logged && !this.$store.state.iframe"
            class="   h-11 bg-green-500 rounded-md text-white text-xs text-center hover:bg-green-500 transition-colors"
            style="font-size: 16px;width: auto;padding: 16px"

            onclick="location.href='/auth/user/login';"
        >                 <font-awesome-icon icon="video"  class="mr-2"/>
           {{ $t('LiveSale.become-a-publisher') }}</button>
      </div>

      <LiveGrid v-if="!this.$store.state.iframe" />
    </div>
    <template v-else>
      <a-divider />
      <template v-if="spinning" >
        <a-spin  :spinning="spinning"  />
      </template>
      <template v-else>
        <div class="sm:order-3 md:w-1/5 sm:w-full h-full col-fixed left">
          <Products  />
        </div>
        <div class="sm:order-1 md:w-3/5 sm:w-full center" id="customer-live-screen">
          <LiveScreen />
          <template v-if="this.$store.state.liveVideo.interaction===undefined || this.$store.state.liveVideo.interaction===true">
            <LiveGrid v-if="!this.$store.state.iframe" />
          </template>
          <p v-show="this.$store.state.emptyEvent">
            {{ $t('LiveSale.stream-not-found') }}
          </p>
        </div>
        <div class="sm:order-2 sm:mb-5 md:w-1/5 sm:w-full col-fixed right">
          <Chat  />
        </div>
      </template>
    </template>

</template>

<script>

import Products from '@/components/live/Products'
import LiveScreen from '@/components/live/LiveScreen'
import LiveGrid from "@/views/Customer/LiveGrid";
import Chat from '@/components/live/Chat'

export default {
    components: {
      Products, LiveScreen, Chat, LiveGrid
    },
    data(){
      return{
        EventIsNotLive:false,
        spinning:true,
        url:process.env.VUE_APP_URL
      }
    },
  async beforeCreate() {
    this.$router.push({ path: '/user' });
    return false;
    // let  id = this.$route.params.id;
    //   if(this.$route.params.id === undefined && this.$route.params.eventCode === undefined){
    //     await this.$appAxios.get('/randomEvent').then((result)=>{
    //       id = result.data.data.eventId;
    //       if (id>0){
    //         this.$router.push({ path: '/'+id });
    //       }
    //     });
    //   }
    //
    //   if (this.$route.name === "LiveSaleByEventCode"){
    //     this.$appAxios.get('/event/0?eventCode='+ this.$route.params.eventCode).then((result)=>{
    //       this.$store.state.eventId = id;
    //       localStorage.eventId = id;
    //       this.$store.state.channelType = result.data.data.channel.type
    //       this.$store.state.products = result.data.data.products;
    //       this.$store.state.statistics = result.data.data.statistics;
    //       this.$store.state.showChat = result.data.data.use_chat;
    //
    //       this.$store.state.liveVideo.title = result.data.data.title
    //       //this.$store.state.liveVideo.description = result.data.data.description
    //       this.$store.state.liveVideo.playbackUrl = result.data.data.playback_url;
    //       this.$store.state.liveVideo.liveDate = result.data.data.date;
    //
    //       if (result.data.data.interaction !== undefined && this.$route.params.id !== undefined){
    //         //NOT:: Eğer urlden gelmemişse randomdan gelmişse etkileşim açık (this.$route.params.id !== undefined)
    //         this.$store.state.liveVideo.interaction = result.data.data.interaction;
    //       }else {
    //         this.$store.state.liveVideo.interaction = true;
    //       }
    //
    //       // let date = new Date(result.data.data.date);
    //       // var options = { weekday: 'long',month:'long',day:'numeric'};
    //       // this.$store.state.liveVideo.date = new Intl.DateTimeFormat('tr-TR', options).format(date)
    //       // options = {hour:'numeric',minute:"numeric"};
    //       // this.$store.state.liveVideo.hour = new Intl.DateTimeFormat('tr-TR', options).format(date)
    //
    //
    //       //highlight-product
    //       this.$store.state.highlightProduct = this.$store.state.products.filter(item => item.is_highlight === true)
    //       this.$store.state.products = this.$store.state.products.filter(item => item.is_highlight !== true)
    //
    //
    //       //load
    //       this.spinning = false
    //       this.$store.state.emptyEvent=false
    //
    //       const titleEl = document.querySelector('head title');
    //
    //       titleEl.textContent = result.data.data.title;
    //
    //     }).catch(()=>{
    //
    //       this.EventIsNotLive=true
    //       this.spinning = false
    //     });
    //   }else if ((this.$route.name !== "LiveSale" || this.$route.name !== "main") && id !=0){
    //
    //     this.$appAxios.get('/event/'+ id).then((result)=>{
    //       this.$store.state.eventId = id;
    //       localStorage.eventId = id;
    //       this.$store.state.channelType = result.data.data.channel.type
    //       this.$store.state.products = result.data.data.products;
    //       this.$store.state.statistics = result.data.data.statistics;
    //       this.$store.state.showChat = result.data.data.use_chat;
    //
    //       this.$store.state.liveVideo.title = result.data.data.title
    //       //this.$store.state.liveVideo.description = result.data.data.description
    //       this.$store.state.liveVideo.playbackUrl = result.data.data.playback_url;
    //       this.$store.state.liveVideo.liveDate = result.data.data.date;
    //
    //       if (result.data.data.interaction !== undefined && this.$route.params.id !== undefined){
    //         //NOT:: Eğer urlden gelmemişse randomdan gelmişse etkileşim açık (this.$route.params.id !== undefined)
    //         this.$store.state.liveVideo.interaction = result.data.data.interaction;
    //       }else {
    //         this.$store.state.liveVideo.interaction = true;
    //       }
    //
    //       // let date = new Date(result.data.data.date);
    //       // var options = { weekday: 'long',month:'long',day:'numeric'};
    //       // this.$store.state.liveVideo.date = new Intl.DateTimeFormat('tr-TR', options).format(date)
    //       // options = {hour:'numeric',minute:"numeric"};
    //       // this.$store.state.liveVideo.hour = new Intl.DateTimeFormat('tr-TR', options).format(date)
    //
    //
    //       //highlight-product
    //       this.$store.state.highlightProduct = this.$store.state.products.filter(item => item.is_highlight === true)
    //       this.$store.state.products = this.$store.state.products.filter(item => item.is_highlight !== true)
    //
    //
    //       //load
    //       this.spinning = false
    //       this.$store.state.emptyEvent=false
    //
    //       const titleEl = document.querySelector('head title');
    //
    //       titleEl.textContent = result.data.data.title;
    //
    //     }).catch(()=>{
    //       this.EventIsNotLive=true
    //       this.spinning = false
    //     });
    //   }else {
    //     this.$store.state.products = [];
    //     this.$store.state.highlightProduct = [];
    //     this.$store.state.emptyEvent=true
    //     this.spinning = false
    //   }

    }
}
</script>