<template>
  <a-spin :spinning="spinningLiveEvent">
    <a-layout-content class="sm:p-0 md:px-5" :style="{ margin: '24px 0 0' }" v-if="this.$store.state.liveEvents.length>0 && (this.$route.name ==='LiveSale' || this.$route.name ==='main')">
      <div @click="toggleEl($event)" class="toggle-title card-title flex items-center justify-between w-full" style="margin-bottom: 12px">
          <div class="flex items-center">
              <span class="anticon w-10 h-10">
                  <font-awesome-icon icon="tv" class="text-blue-500 text-base h-10"/>
              </span>
              <span class="text-lg font-medium ml-3">Canlı yayınlar</span>
          </div>
          <span class="anticon w-10 h-10 float-right md:hidden sm:block">
              <font-awesome-icon icon="chevron-down" class="text-blue-500 text-base h-10"/>
              <font-awesome-icon icon="chevron-up" class="text-blue-500 text-base h-10"/>
          </span>
      </div>
      <a-row class="toggle-menu" :gutter="16"  >
        <a-col class="gutter-row" :sm="{ span: 24 }" :md="{ span: this.$store.state.emptyEvent ? 4:6 }" v-for="event in this.$store.state.liveEvents" :key="event.id">
          {{event}}
          <a :href="'/'+event.id"  >
            <a-card hoverable   style="width: 100% !important;margin-top: 10px;max-height: 250px">
              <img
                  style="height: 150px !important;width: 100%"
                  alt="example"
                  :src="event.image_urls === null ? 'https://media.istockphoto.com/vectors/live-stream-icon-live-streaming-video-news-symbol-on-transparent-vector-id1217456186?k=20&m=1217456186&s=170667a&w=0&h=0NjpmtWkba1AtHX1DqkhT0Y8smXX1xdnRv8G3fo0jQk=': event.image_urls[0]"
              />

              <template  class="ant-card-actions">
                <a-icon key="setting" type="setting" />
                <a-icon key="edit" type="edit" />
                <a-icon key="ellipsis" type="ellipsis" />
              </template>
              <a-card-meta :title="event.title" :description="(undefined !== event.description && event.description.length > 40) ? event.description.substr(0, 40-1) + '&hellip;' : event.description">
                <a-avatar
                    src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                />
              </a-card-meta>
            </a-card>
          </a>
        </a-col>
      </a-row>
    </a-layout-content>
  </a-spin>
  <a-spin :spinning="spinningRecord">
    <a-layout-content class="sm:p-0 md:px-5" :style="{ margin: '24px 0 0' }" v-if="this.$store.state.records.length>0 && (this.$route.name ==='LiveSale' || this.$route.name ==='main')">
      <div @click="toggleEl($event)" class="toggle-title card-title flex items-center justify-between w-full" style="margin-bottom: 12px">
          <div class="flex items-center">
              <span class="anticon w-10 h-10">
                  <font-awesome-icon icon="tv" class="text-blue-500 text-base h-10"/>
              </span>
              <span class="text-lg font-medium ml-3">{{ $t('stream-record') }}</span>
          </div>
          <span class="anticon w-10 h-10 float-right md:hidden sm:block">
              <font-awesome-icon icon="chevron-down" class="text-blue-500 text-base h-10"/>
              <font-awesome-icon icon="chevron-up" class="text-blue-500 text-base h-10"/>
          </span>
      </div>
      <a-row class="toggle-menu" :gutter="16" >
        <a-col class="gutter-row" :sm="{ span: 24 }" :md="{ span: this.$store.state.emptyEvent ? 4:6 }" v-for="event in this.$store.state.records" :key="event.id">
          <a :href="'/record/'+event.id"  >
            <a-card hoverable   style="width: 100% !important;margin-top: 10px;max-height: 250px">
              <img
                  style="height: 150px !important;width: 100%"
                  alt="example"
                  :src="event.image_urls === null ? 'https://media.istockphoto.com/vectors/live-stream-icon-live-streaming-video-news-symbol-on-transparent-vector-id1217456186?k=20&m=1217456186&s=170667a&w=0&h=0NjpmtWkba1AtHX1DqkhT0Y8smXX1xdnRv8G3fo0jQk=': event.image_urls[0]"
              />

              <template  class="ant-card-actions">
                <a-icon key="setting" type="setting" />
                <a-icon key="edit" type="edit" />
                <a-icon key="ellipsis" type="ellipsis" />
              </template>
              <a-card-meta :title="event.title" :description="(undefined !== event.description && event.description.length > 40) ? event.description.substr(0, 40-1) + '&hellip;' : event.description">
                <a-avatar
                    src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                />
              </a-card-meta>
            </a-card>
          </a>
        </a-col>
      </a-row>
    </a-layout-content>
  </a-spin>
  <a-spin :spinning="spinningUpcomingEvents">
    <a-layout-content class="sm:p-0 md:px-5" :style="{ margin: '24px 0 0' }" v-if="this.$store.state.upcomingEvents.length>0 && (this.$route.name ==='LiveSale' || this.$route.name ==='main')">
      <div @click="toggleEl($event)" class="toggle-title card-title flex items-center justify-between w-full" style="margin-bottom: 12px">
        <div class="flex items-center">
              <span class="anticon w-10 h-10">
                  <font-awesome-icon icon="tv" class="text-blue-500 text-base h-10"/>
              </span>
          <span class="text-lg font-medium ml-3">{{ $t('upcoming-events') }}</span>
        </div>
        <span class="anticon w-10 h-10 float-right md:hidden sm:block">
              <font-awesome-icon icon="chevron-down" class="text-blue-500 text-base h-10"/>
              <font-awesome-icon icon="chevron-up" class="text-blue-500 text-base h-10"/>
          </span>
      </div>
      <a-row class="toggle-menu" :gutter="16" >
        <a-col class="gutter-row" :sm="{ span: 24 }" :md="{ span: this.$store.state.emptyEvent ? 4:6 }" v-for="event in this.$store.state.upcomingEvents" :key="event.id">
          <a :href="'/'+event.id"  >
            <a-card hoverable   style="width: 100% !important;margin-top: 10px;max-height: 250px">
              <img
                  style="height: 150px !important;width: 100%"
                  alt="example"
                  :src="event.image_urls === null ? 'https://media.istockphoto.com/vectors/live-stream-icon-live-streaming-video-news-symbol-on-transparent-vector-id1217456186?k=20&m=1217456186&s=170667a&w=0&h=0NjpmtWkba1AtHX1DqkhT0Y8smXX1xdnRv8G3fo0jQk=': event.image_urls[0]"
              />

              <template  class="ant-card-actions">
                <a-icon key="setting" type="setting" />
                <a-icon key="edit" type="edit" />
                <a-icon key="ellipsis" type="ellipsis" />
              </template>
              <a-card-meta :title="event.title" :description="(undefined !== event.description && event.description.length > 40) ? event.description.substr(0, 40-1) + '&hellip;' : event.description">
                <a-avatar
                    src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                />
              </a-card-meta>
            </a-card>
          </a>
        </a-col>
      </a-row>
    </a-layout-content>
  </a-spin>
</template>

<script>
export default {

  name: "LiveGrid",
  data(){
    return{
      spinningLiveEvent:true,
      spinningRecord:true,
      spinningUpcomingEvents:true
    }
  },
  beforeCreate() {
    if (this.$route.params.id === undefined){
      this.$route.params.id =''
    }

    this.$appAxios.get('/liveEventList?exceptId='+this.$route.params.id).then((result)=>{
      this.$store.state.liveEvents = result.data.data.events;
      this.spinningLiveEvent = false;
    });

    this.$appAxios.get('/listRecords').then((result)=>{
      this.$store.state.records = result.data.data.events;
      this.spinningRecord = false
    });

    this.$appAxios.get('/listUpcomingEvents?exceptId='+this.$route.params.id).then((result)=>{
      this.$store.state.upcomingEvents = result.data.data.events;
      this.spinningUpcomingEvents = false
    });
  },
  methods: {
    toggleEl(elem) {
      var self = elem.target.className.indexOf('toggle-title') > -1 ? elem.target : elem.target.parentNode;
      self.classList.toggle('active');
    }
  }
}
</script>

<style scoped>

</style>