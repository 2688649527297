<template>
    <div class="w-full h-full bg-white rounded-md">
        <div class="card-top sm:px-0 px-4 mb-2 !flex justify-between items-center border-b border-gray-550">
            <div class="card-title flex items-center">
                <span class="anticon w-8">
                    <font-awesome-icon icon="comment-dots" class="text-blue-400 text-lg h-10"/>
                </span>
                <span class="text-lg font-medium ml-3">{{ $t('customer.liveSale.chatBlock.chat') }}</span>

            </div>
          <button @click="connectMessage" v-if="this.$store.state.layout=='user'" class="ml-5" ><font-awesome-icon icon="retweet" style="color: green" /></button>
          <button @click="closeChatToggle" v-if="this.$store.state.layout=='user'" class="ml-5" >Sohbeti kapat</button>
        </div>

      <div ref="chat" class="overflow-y-auto mb-3"  id="customer-chat">
            <div id="messages" class="h-scroll w-full py-3 flex flex-wrap items-center px-4" v-if="this.$store.state.showChat">
                <div v-for="(message, index) in this.messages" :key="index" class="w-full flex flex-wrap items-center mb-3"  >
                    <div class="flex flex-wrap items-center mb-3">
                        <span class="flex items-center mb-2">
                          <img src="@/assets/img/chatProfil.png" alt="" class="mr-2">
                          <template v-if="message.userType == 'user'" >
                                <b style="color: red">{{ message.Name }} - Yayıncı</b>
                          </template>
                          <template v-else>
                                    {{ message.Name }}
                          </template>
                        </span>
                        <span class="text-xs text-gray-300 flex items-center justify-between w-full">
                          {{new Intl.DateTimeFormat('tr-TR', { hour: 'numeric', minute: 'numeric',weekday: 'long',day:'numeric',month:'long'}).format(new Date(message.MessageTime*1000))}}
                          <template v-if="this.$store.state.layout ==='user'">
                              <button class="text-red-500 font-medium" @click="blockUser(message.CustomerId)">Engelle</button>
                          </template>
                        </span>
                    </div>
                    <div class="w-full border border-gray-301 rounded-b-lg rounded-tr-lg p-2 text-xs mb-2">
                      {{ message.message }}
                    </div>
                </div>
            </div>
            <div v-else>
              <p class="w-full mt-3">
                Sohbet bu yayın için kullanılmıyor.
              </p>
            </div>
        </div>

        <div class="w-full border-t border-gray-550 flex items-center pt-2" v-if="this.$store.state.showChat">
          <template v-if="this.$store.state.is_customer_logged && this.$store.state.layout !=='user'">
            <input type="chatMessage"  @keyup.enter="sendMessage" :placeholder="$t('customer.liveSale.chatBlock.type-message')" class="w-full h-9 leading-9 p-2 text-xs text-gray-300" v-model=message >
          </template>
          <template v-else-if="this.$store.state.is_logged && this.$store.state.layout ==='user'">
            <input type="chatMessage"  @keyup.enter="sendMessage" :placeholder="$t('customer.liveSale.chatBlock.type-message')" class="w-full h-9 leading-9 p-2 text-xs text-gray-300" v-model=message >
          </template>
          <template v-else>
            <a href="/auth/customer/login"   class="w-full h-9 leading-9 p-4 ml-3 text-xs m-2 text-gray-300" >{{$t('customer.liveSale.chatBlock.please-login')}}</a>
          </template>
            <button v-if="this.$store.state.is_customer_logged || this.$store.state.is_logged" class="anticon bg-blue-500 w-8 h-7 ml-3 rounded-3xl" @click="sendMessage">
                <font-awesome-icon icon="location-arrow" class="text-white text-xs"/>
            </button>
        </div>
    </div>
</template>
<script>
export default{
  data(){
    return{
      messages:[],
      message:"",
      socket:{},
      token:""
    }
  },
  mounted() {
    document.getElementById("customer-chat").style.height = (window.innerHeight - 205) + "px" ;
    this.$appAxios.get('https://qllo4i04i8.execute-api.eu-west-1.amazonaws.com/dev?eventId='+this.$route.params.id).then((res)=>{
      this.messages = [];
      console.log(res)
      if (typeof  res.data.messages !== "undefined"){
        res.data.messages.forEach((value)=>{
          this.messages.push(value)
        })
        this.$refs.chat.scrollTop = this.$refs.chat.scrollHeight
      }
    })
  },
  async created()  {
      if (this.$store.state.is_logged || this.$store.state.is_customer_logged){
        if (this.$store.state.layout === "user"){
          this.token = localStorage.token;
        }else {
          this.token = localStorage.customerToken;
        }
      }
      this.socket = await  new WebSocket('wss://w8ndag5x7i.execute-api.eu-west-1.amazonaws.com/dev?room='+ this.$route.params.id +'&token='+ this.token);
      this.socket.onopen = () => {
        console.log('Websocket connected.');
        this.$refs.chat.scrollTop = this.$refs.chat.scrollHeight
      }
      this.socket.onclose = async ()  => {
        this.socket = await  new WebSocket('wss://w8ndag5x7i.execute-api.eu-west-1.amazonaws.com/dev?room='+ this.$route.params.id +'&token='+ this.token);
      }
      this.socket.onmessage= async (event) => {
        let item = JSON.parse(event.data);
        if (item.message !== "Internal server error"){
          await this.messages.push(JSON.parse(event.data))
        }
        this.$refs.chat.scrollTop = this.$refs.chat.scrollHeight
      }

  },
  watch:{
    '$store.state.eventId': function() {
        this.messages=[]
    }
  },
  methods:{
    closeChatToggle(){
      this.$appAxios.get('/closeChatToggle?eventId=' +this.$route.params.id ,{
        headers: {
          Authorization: [`Bearer ${localStorage.getItem("token")}`]
        }
      })
      .then((res)=>{
        this.$store.state.showChat = res.data.data.use_chat;
      }).catch(()=>{
        this.$swal({
          icon:'error',
          title:'Sohbet ekranı güncellenirken hata oluştu',
        })
      })
    },
    blockUser(customerId){
      console.log(customerId)
      this.$appAxios.post('/blockUserFromChat',{
        "customerId":customerId,
        "eventId":this.$route.params.id
      }, {
            headers: {
              Authorization: [`Bearer ${localStorage.getItem("token")}`]
            }
          })
        .then(()=>{
          this.$swal({
            icon:'success',
            title:'kullanıcı engellendi',
          })
        }).catch(()=>{
          this.$swal({
            icon:'error',
            title:'kullanıcı engellenirken hata oluştu',
          })
      })
    },
    async connectMessage(){
      this.socket.close();
      if (this.$store.state.is_logged || this.$store.state.is_customer_logged){
        if (this.$store.state.layout === "user"){
          this.token = localStorage.token;
        }else {
          this.token = localStorage.customerToken;
        }
      }
      this.socket = await  new WebSocket('wss://w8ndag5x7i.execute-api.eu-west-1.amazonaws.com/dev?room='+ this.$route.params.id +'&token='+ this.token);
      this.socket.onopen = () => {
        console.log('Chat Websocket connected.');
        this.$refs.chat.scrollTop = this.$refs.chat.scrollHeight
      }
      this.socket.onmessage= async (event) => {
        await this.messages.push(JSON.parse(event.data))
        this.$refs.chat.scrollTop = this.$refs.chat.scrollHeight
      }
      this.$appAxios.get('https://qllo4i04i8.execute-api.eu-west-1.amazonaws.com/dev?eventId='+this.$route.params.id).then((res)=>{
        this.messages = [];
        if (typeof  res.data.messages !== "undefined") {
          res.data.messages.forEach((value)=>{
            this.messages.push(value)
          })
          this.$refs.chat.scrollTop = this.$refs.chat.scrollHeight
        }

      })
    },

    sendMessage(){
      if(this.$store.state.is_customer_logged || this.$store.state.is_logged) {

       if(this.$store.state.layout === 'customer'){

         if(!this.$store.state.customer.verified){
           this.$swal({
             icon:'warning',
             title:this.$t('auth.verifyEmail.verification-required-title'),
             text:this.$t('auth.verifyEmail.verification-required-text')
           })
           return
         }

       }else if(this.$store.state.layout === 'user'){

         if(!this.$store.state.user.verified){
           this.$swal({
             icon:'warning',
             title:this.$t('auth.verifyEmail.verification-required-title'),
             text:this.$t('auth.verifyEmail.verification-required-text')
           })
           return
         }
       }
        //this.socket.emit('message', {message: this.message, "MessageDate": Math.floor(Date.now()).toString()});

        // eslint-disable-next-line no-unreachable
        console.log("send message çalıştı")
        if (this.message==""){
          return false;
        }
        this.socket.send(JSON.stringify({
          "action": "message",
          message: this.message,
          "RoomID": this.$route.params.id,
          "Token":this.token
        }))
        this.message = '';
      }
    }
  }
}
</script>
<style>
.font-semibold{
  font-weight: bold;
}
</style>